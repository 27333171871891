// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-referrals-js": () => import("./../../../src/pages/about-referrals.js" /* webpackChunkName: "component---src-pages-about-referrals-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact-thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-for-business-js": () => import("./../../../src/pages/for-business.js" /* webpackChunkName: "component---src-pages-for-business-js" */),
  "component---src-pages-for-individuals-js": () => import("./../../../src/pages/for-individuals.js" /* webpackChunkName: "component---src-pages-for-individuals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

